import { SET_EXTRA_BONUS, SET_DAILY_TASK, SET_FIRST_RECHARGE_ACTIVITY, SET_IS_OPEN_WHEEL, SET_IS_BONUS } from '../constants'
import { deepClone } from '@utils'

export interface ExtraBonusDataList {
  id: number;
  name: string;
  images: string;
  pay_id: number;
  amount: string;
  rebate: string;
  number: number
  start_date: string;
  end_date: string;
  status: number;
  is_delete: number;
  created_at: string;
  updated_at: string;
  recharge_number: number;
  surplus_date: string;
  pay_info: any;
}

interface ExtraBonusData {
  count: number;
  list: ExtraBonusDataList[] | [];
  page: number;
  perPage: number;
  pageCount: number;
}

export interface DailyTaskList {
  id: number;
  name: string,
  games_id: number;
  sub_games_id: number;
  bet_number: number,
  images: string;
  bet_amount: string;
  task_number: number;
  start_date: string;
  end_date: string;
  status: number;
  is_delete: number;
  created_at: string;
  updated_at: string;
  user_number: number;
  surplus_date: number;
  remainingCount: number;
  currentBetCount: number;
  finishCount: number;
}

interface DailyTaskData {
  count: number;
  list: DailyTaskList[] | [];
  page: number;
  perPage: number;
  pageCount: number;
}

interface FirstRechargeItem {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  min_amount: string;
  rate: string;
  h5_image: string;
  pc_image: string;
  status: number;
  is_delete: number;
  created_at: string;
  updated_at: string;
}

interface State {
  isOpenWheel: boolean;
  extraBonusData: ExtraBonusData;
  dailyTaskData: DailyTaskData;
  firstRechargeData: FirstRechargeItem;
  isBonus: boolean
}

interface Action {
    type: string;
    value: any;
}

const initData = {
  count: 1,
  list: [],
  page: 1,
  perPage: 30,
  pageCount: 1
}

const initValue: State = {
  isOpenWheel: false,
  extraBonusData: initData,
  dailyTaskData: initData,
  firstRechargeData: {} as FirstRechargeItem,
  //  这个标识用来表示奖金页面充值
  isBonus: false
}

export default (state: State = initValue, action: Action) => {
  const _state = deepClone(state)
  switch (action.type) {
    case SET_EXTRA_BONUS:
      _state.extraBonusData = action.value
      return _state
    case SET_DAILY_TASK:
      _state.dailyTaskData = action.value
      return _state
    case SET_FIRST_RECHARGE_ACTIVITY:
      _state.firstRechargeData = action.value
      return _state
    case SET_IS_OPEN_WHEEL:
      _state.isOpenWheel = action.value
      return _state
    case SET_IS_BONUS:
      _state.isBonus = action.value
      return _state
    default:
      return _state
  }
}
