import { SET_WEB_PUBLIC, SET_WEB_CONFIG, SET_MAINTAIN_DATA, SET_IS_OPEN_ANNOUNCEMENT } from '../constants'
import { deepClone } from '@utils'

interface Action {
  type: string;
  value: any;
}

const templates: Record<any, string> = {
  0: 'originalDark',
  1: 'gradientDark',
  2: 'originalLight',
  3: 'gradientLight'
}

const initValue: any = {
  webPublic: {
    template: 'originalDark'
  },
  webConfig: {},
  maintainData: {
    open: 0,
    web_time: '',
    text: '',
    customer_service: '',
    CS: ''
  },
  isOpenAnnouncement: false
}

export default (state: any = initValue, action: Action) => {
  const _state = deepClone(state)
  let template = templates['2']
  if (action.type === SET_WEB_PUBLIC) {
    // template = templates[String(action.value.typesetting?.find((item: any) => item.type === 1)?.marked) ?? '2']
  }
  template = 'originalLight'

  document.body.classList.add(template)

  switch (action.type) {
    case SET_WEB_PUBLIC:
      _state.webPublic = {
        ...action.value,
        template
      }
      return _state
    case SET_WEB_CONFIG:
      _state.webConfig = action.value
      return _state
    case SET_MAINTAIN_DATA:
      _state.maintainData = action.value
      return _state
    case SET_IS_OPEN_ANNOUNCEMENT:
      _state.isOpenAnnouncement = action.value
      return _state
    default:
      return _state
  }
}
