// public
export const SET_WEB_PUBLIC = 'SET_WEB_PUBLIC'
export const SET_WEB_CONFIG = 'SET_WEB_CONFIG'
export const SET_MAINTAIN_DATA = 'SET_MAINTAIN_DATA'
export const SET_IS_OPEN_ANNOUNCEMENT = 'SET_IS_OPEN_ANNOUNCEMENT'

// home
export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT'
export const SET_BANNER = 'SET_BANNER'
export const SET_GAME_TYPE_INFO = 'SET_GAME_TYPE_INFO'
// export const SET_HOT_GAME_LIST = 'SET_HOT_GAME_LIST'
export const SET_GAME_LIST = 'SET_GAME_LIST'
export const SET_HOT_GAME = 'SET_HOT_GAME'
export const SET_HAS_CLOSE_ANNOUNCEMENT = 'SET_HAS_CLOSE_ANNOUNCEMENT'
export const SET_RECENT_GAME = 'SET_RECENT_GAME'

// user
export const SET_IS_OPEN_LOGIN = 'SET_IS_OPEN_LOGIN'
export const SET_USERINFO = 'SET_USERINFO'
export const CLEAR_USERINFO = 'CLEAR_USERINFO'
export const SET_VIP_INFO = 'SET_VIP_INFO'
export const SET_IS_USERINFO_OPEN = 'SET_IS_USERINFO_OPEN'
export const SET_LOGIN_REGISTER_TYPE = 'SET_LOGIN_REGISTER_TYPE'

// wallet
export const SET_IS_OPEN_WALLET = 'SET_IS_OPEN_WALLET'
export const SET_PAY_INFO = 'SET_PAY_INFO'
export const SET_WALLET_TYPE = 'SET_WALLET_TYPE'

// activities
export const SET_EXTRA_BONUS = 'SET_EXTRA_BONUS'
export const SET_DAILY_TASK = 'SET_DAILY_TASK'
export const SET_FIRST_RECHARGE_ACTIVITY = 'SET_FIRST_RECHARGE_ACTIVITY'
export const SET_IS_OPEN_WHEEL = 'SET_IS_OPEN_WHEEL'
export const SET_IS_BONUS = 'SET_IS_BONUS'

// records
export const SET_GAME_CATEGORY = 'SET_GAME_CATEGORY'
export const SET_GAME_BET_RECORD = 'SET_GAME_BET_RECORD'
