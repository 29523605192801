import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/public.less'
import './utils/resize.js'
import 'antd/dist/reset.css'
import store from './store'
import { Provider } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import routers from './routers'
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch } from 'react-router-dom'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
      <Provider store={store} >
          {/* <Suspense fallback={<div style={{ width: '100vw', height: '100vh', textAlign: 'center', color: '#FFF', fontSize: '20px', lineHeight: '100vh' }}>Loading...</div>}> */}
          {/*  <HashRouter> */}
          {/*    <Switch> */}
          {/*      {renderRoutes(routers)} */}
          {/*    </Switch> */}
          {/*  </HashRouter> */}
          {/* </Suspense> */}

        <BrowserRouter>
          <Switch>
            {renderRoutes(routers)}
          </Switch>
        </BrowserRouter>
      </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
