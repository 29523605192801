import { lazy } from 'react'
// import { Redirect } from 'react-router-dom'

const App = lazy(() => import('../App'))
const AllGames = lazy(() => import('../views/allGames'))
const Promo = lazy(() => import('../views/promo'))
const PromoDetail = lazy(() => import('../views/promo/promoDetail'))
const DailyTasks = lazy(() => import('../views/dailyTasks'))
const SpecialTask = lazy(() => import('../views/specialTask'))
const ExtraBonus = lazy(() => import('../views/extraBonus'))
const Vip = lazy(() => import('../views/vip'))
const Recommend = lazy(() => import('../views/recommend'))
const PersonalCenter = lazy(() => import('../views/personalCenter'))
const FundingHistory = lazy(() => import('../views/fundingHistory'))
const GameHistory = lazy(() => import('../views/gameHistory'))
const PlayGame = lazy(() => import('../views/playGame'))
const Help = lazy(() => import('../views/help'))
const ActivityDetails = lazy(() => import('../views/activity/activityDetails'))

// const Maintain = lazy(() => import('../views/maintain'))

export default [
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: AllGames
        // render:()=>{
        //     return <Redirect to={'/admin/control'}></Redirect>
        // }
      },
      {
        path: '/promo',
        exact: true,
        component: Promo
      },
      {
        path: '/promoDetail',
        exact: true,
        component: PromoDetail
      },
      {
        path: '/dailyTasks',
        exact: true,
        component: DailyTasks
      },
      {
        path: '/specialTask',
        exact: true,
        component: SpecialTask
      },
      {
        path: '/extraBonus',
        exact: true,
        component: ExtraBonus
      },
      {
        path: '/vip',
        exact: true,
        component: Vip
      },
      {
        path: '/recommend',
        exact: true,
        component: Recommend
      },
      {
        path: '/personalCenter',
        exact: true,
        component: PersonalCenter
      },
      {
        path: '/fundingHistory',
        exact: true,
        component: FundingHistory
      },
      {
        path: '/gameHistory',
        exact: true,
        component: GameHistory
      },
      {
        path: '/playGame',
        exact: true,
        component: PlayGame
      },
      {
        path: '/help',
        exact: true,
        component: Help
      },
      {
        path: '/activityDetails',
        exact: true,
        component: ActivityDetails
      },
      // {
      //   path: '/maintain',
      //   exact: true,
      //   component: Maintain
      // }
      {
        path: '*',
        exact: true,
        component: AllGames
      }
    ]
  }
  // {
  //   path: '/maintain',
  //   exact: true,
  //   component: Maintain
  // }
]
